// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.App-logo {
  height: 50px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
}

.Header-logo {
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid #fff;
  margin-bottom: 20px;
}

.App-header {
  background-color: #1d1f21;
  padding: 60px 0;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.App-header h1 {
  font-size: 3em;
  margin: 0.5em 0;
}

.App-header p {
  font-size: 1.5em;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.button-group .btn {
  min-width: 120px;
}

.App-main {
  flex: 1 1;
  padding: 40px 0;
  background-color: #f8f9fa;
}

.App-section {
  margin: 40px 0;
}

.App-footer {
  background-color: #1d1f21;
  padding: 20px 0;
  color: white;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.App-footer p {
  margin: 0;
}

@media (min-width: 768px) {
  .App-header h1 {
    font-size: 4em;
  }

  .App-header p {
    font-size: 1.75em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ,yCAAyC;AAC3C;;AAEA;EACE,SAAS;AACX;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["html, body {\n  height: 100%;\n  margin: 0;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  text-align: center;\n  font-family: 'Arial', sans-serif;\n}\n\n.App-logo {\n  height: 50px;\n  margin-right: 10px;\n  object-fit: cover;\n  border-radius: 50%;\n  border: 2px solid #fff;\n}\n\n.Header-logo {\n  height: 150px;\n  object-fit: cover;\n  border-radius: 10px;\n  border: 2px solid #fff;\n  margin-bottom: 20px;\n}\n\n.App-header {\n  background-color: #1d1f21;\n  padding: 60px 0;\n  color: white;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.App-header h1 {\n  font-size: 3em;\n  margin: 0.5em 0;\n}\n\n.App-header p {\n  font-size: 1.5em;\n}\n\n.button-group {\n  display: flex;\n  justify-content: center;\n  margin-top: 1em;\n}\n\n.button-group .btn {\n  min-width: 120px;\n}\n\n.App-main {\n  flex: 1;\n  padding: 40px 0;\n  background-color: #f8f9fa;\n}\n\n.App-section {\n  margin: 40px 0;\n}\n\n.App-footer {\n  background-color: #1d1f21;\n  padding: 20px 0;\n  color: white;\n  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.App-footer p {\n  margin: 0;\n}\n\n@media (min-width: 768px) {\n  .App-header h1 {\n    font-size: 4em;\n  }\n\n  .App-header p {\n    font-size: 1.75em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
