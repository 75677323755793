import React from 'react';
import { Navbar, Container, Row, Col, Button } from 'react-bootstrap';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <img src='/logo.jpg' alt="Diamond Shine Detail" className="App-logo" />
            Diamond Shine Detail
          </Navbar.Brand>
        </Container>
      </Navbar>

      <header className="App-header">
        <Container>
          <Row className="justify-content-md-center text-center">
            <Col md="auto">
              <img src='/logo.jpg' alt="Diamond Shine Detail" className="Header-logo" />
              <h1>Welcome to Diamond Shine Detail</h1>
              <p>Mobile detailing service in Leicestershire</p>
              <span>Follow us!</span>
              <div className="button-group">
                <Button className="m-2" variant="primary" href="https://www.tiktok.com/@diamondshinedetail" target="_blank">
                  TikTok
                </Button>
                <Button className="m-2" variant="primary" href="https://www.facebook.com/profile.php?id=61560653590491" target="_blank">
                  Facebook
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <main className="App-main">
        <Container>
          <section id="contact" className="App-section">
            <h2>Contact Us</h2>
            <p>For inquiries, please email us!</p>
            <Button className="m-2" variant="primary" href="mailto:Jack.diamonshine@gmail.com">
              Send Email
            </Button>
          </section>
        </Container>
      </main>

      <footer className="App-footer">
        <Container>
          <Row>
            <Col>
              <p>&copy; 2024 Diamond Shine Detail. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
